.home
    .carousel
        margin-top: 50px
        border-radius: 20px
        box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22)
    &__title
        font-size: 32px
        color: #FF4B2B
        text-align: center
    h2
        font-size: 34px
        font-weight: 500
        text-align: center
    &__possibilities
        margin-top: 30px
        ul
            list-style: none
            display: flex
            justify-content: space-around
            padding-top: 25px
            li
                max-width: 350px
                text-align: center
    &__blog
        margin-top: 30px
        &__wrapper
            padding-top: 25px
            display: flex
            align-items: center
            justify-content: center
            flex-wrap: wrap
            gap: calc( 80px / 3 )
        &__btn
            display: block
            margin: 25px auto
            text-align: center
            width: 100px
            cursor: pointer
            border-radius: 20px
            background-color: #FF4B2B
            color: #FFFFFF
            font-size: 12px
            font-weight: bold
            padding: 12px 0
            letter-spacing: 1px
            text-transform: uppercase
            transition: transform 80ms ease-in
            &:hover
                color: #FFFFFF
            &:active
                transform: scale(0.95)
            &:focus
                outline: none