@media screen and (max-width: 1440px)
    .header
        &__wrapper
            padding: 0 30px
@media screen and (max-width: 900px)
    .home
        &__possibilities
            ul
                flex-direction: column
                align-items: center
                gap: 30px
    .guide-by-id
        &__form 
            margin-top: 25px
            width: 100%
            gap: 10px
        &__about 
            width: 100%
            flex-direction: column
            align-items: center
            gap: 10px
        &__text
            margin-top: 15px
    .admin-panel__add-form 
        flex-direction: column
        align-items: center
        padding: 0 20px
        input, select, textarea 
            width: 100%
        div
            width: 100%
        input[type=submit]
            width: 200px



@media screen and (max-width: 500px)
    main
        padding-top: 25px
    .header
        &__hamburger
            display: block
            position: absolute
            top: 30px
            right: 20px
            font-size: 24px
        &__close
            display: block
            position: absolute
            top: 30px
            right: 20px
            font-size: 24px
        &__wrapper
            position: absolute
            top: 0
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            gap: 20px
            width: 100%
            height: 100vh
            background: #fff
            z-index: 10
        &__wrapper-none
            display: none   
        &__nav
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            gap: 80px
            padding: 0
        &__logo
            display: none
        &__logo-mobile
            display: block
            position: absolute
            top: 25px
            left: 20px
            display: flex
            align-items: center
            gap: 15px
            font-size: 24px
        a
            margin: 0
    .home
        &__possibilities
            ul
                flex-direction: column
                align-items: center
                gap: 30px
        &__blog
            &__wrapper
                flex-direction: column
                align-items: center
    .blog
        &__item
            width: 280px
            height: 465px
    .guides
        &__item 
            width: 280px
            height: 100%
            padding: 20px 20px 50px
            gap: 10px
            flex-direction: column
            align-items: center
        &__about
            height: 100%
        &__preview 
            gap: 10px
    .guide-by-id
        &__img
            width: 300px
        textarea 
            width: 280px
        &__location, &__info
            width: 280px
        option 
            font-size: 12px
        input[type=submit]
            width: 200px

    .search
        width: 280px
        height: 200px
        padding: 10px
        flex-direction: column
        select, input, div
            width: 100%
            font-size: 12px
        div
            justify-content: start
        &__guides-mobile
            height: 130px
    .footer
        height: 200px
        &__links
            display: flex
            flex-direction: column
            align-items: center
            gap: 15px
        a
            font-size: 14px
    .login
        form
            padding: 0 10px
            h1 
                font-size: 24px
        a
            margin: 10px 0       
        input
            font-size: 14px
        &__wrapper
            max-width: 90%
        .sign-in-container 
            width: 100%
            z-index: 4
        .sign-up-container 
            width: 100%
            transition: none
            
            &__active
                opacity: 1
                z-index: 5
        .overlay-container 
            display: none
    .guides__modal
        width: 280px
        padding: 0 20px
        text-align: center
        transform: translate(-50%, -50%)